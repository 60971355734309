<!-- =========================================================================================
  File Name: UserList.vue
  Description: User List page
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <v-card>
    <!-- HEADER TOOLBAR -->
    <v-toolbar class="page-primary-card-header">
      <v-list-item dark>
        <v-list-item-avatar color="primary lighten-2">
          <v-icon>{{ card_header_props.header.icon }}</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="headline">{{
            card_header_props.header.headLine
          }}</v-list-item-title>
          <v-list-item-subtitle>{{
            card_header_props.header.subTitle
          }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-btn
        @click="
          card_header_props.helper.page_helper =
            !card_header_props.helper.page_helper
        "
        icon
        dark
      >
        <v-icon>info</v-icon>
      </v-btn>

      <v-btn @click="startTour" icon dark>
        <v-icon>help</v-icon>
      </v-btn>

      <v-dialog v-model="card_header_props.helper.page_helper" width="500">
        <v-card>
          <v-card-title class="headline grey lighten-2" primary-title>{{
            $t("_common.How_can_I_use_this_form")
          }}</v-card-title>

          <v-card-text>{{
            $t("_user_management._info_1.content")
          }}</v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="card_header_props.helper.page_helper = false"
              >{{ $t("_common.OK") }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-toolbar>
    <!-- HEADER TOOLBAR -->
    <div class="p-5">
      <user-list />
    </div>
  </v-card>
</template>

<script>
import UserList from "./user-list/UserManagementList.vue";
require("../../../../assets/css/neo.css");

export default {
  components: {
    UserList,
  },
  data() {
    return {
      card_header_props: {
        header: {
          headLine: this.$t("_verticalNavbar.UserTransactions"),
          subTitle: this.$t("_user_management.Subtitle"),
          icon: "mdi-account-multiple",
        },
        helper: {
          absolute: true,
          opacity: 0.8,
          overlay: false,
          page_helper: false,
        },
      },
    };
  },
  methods: {
    startTour() {
      this.$tours["userManagement"].options.labels.buttonNext =
        this.$t("_tour.Next");
      this.$tours["userManagement"].options.labels.buttonPrevious =
        this.$t("_tour.Previous");
      this.$tours["userManagement"].options.labels.buttonSkip =
        this.$t("_tour.Skip");
      this.$tours["userManagement"].options.labels.buttonStop =
        this.$t("_tour.Finish");
      this.$tours["userManagement"].start();
    },
  },
};
</script>
