<template>
  <div :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}">
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <feather-icon
          icon="Edit3Icon"
          v-on="on"
          svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer"
          @click="editRecord"
        />
      </template>
      <span>{{$t('_common.Edit')}}</span>
    </v-tooltip>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <feather-icon
          icon="Trash2Icon"
          v-on="on"
          svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer"
          @click="confirmDeleteRecord"
        />
      </template>
      <span>{{$t('_common.Delete')}}</span>
    </v-tooltip>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <feather-icon
          icon="SendIcon"
          v-on="on"
          svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer"
          @click="resendVerificationCode"
        />
      </template>
      <span>{{$t('_user_management.Hover.ResendActivation')}}</span>
    </v-tooltip>

    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <feather-icon
          icon="UnlockIcon"
          v-on="on"
          svgClasses="h-5 w-5 hover:text-danger cursor-pointer"
          @click="resetPassword"
        />
      </template>
      <span>{{$t('_user_management.Hover.ResetPassword')}}</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  name: "CellRendererActions",
  methods: {
    editRecord() {
      this.$router
        .push("/user/management/user-edit/" + this.params.data.Id)
        .catch(() => {});

      /*
          Below line will be for actual product
          Currently it's commented due to demo purpose - Above url is for demo purpose

          this.$router.push("/apps/user/user-edit/" + this.params.data.id).catch(() => {})
        */
    },
    confirmDeleteRecord() {
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: this.$t('_user_management.Confirm_Delete'),
        text:  `"${this.$t('_user_management.You_are_about_to_delete')} ${this.params.data.Email}"`,
        accept: this.deleteRecord,
        acceptText: this.$t('_common.Delete'),
        cancelText : this.$t('_common.Cancel')
      });
    },
    deleteRecord() {
      /* Below two lines are just for demo purpose */
      // this.showDeleteSuccess()

      /* UnComment below lines for enabling true flow if deleting user */
      // this.$store.dispatch("userManagement/removeRecord", this.params.data.id)
      //   .then(()   => { this.showDeleteSuccess() })
      //   .catch(err => { this.$goc.console.error(err)       })

      let currentUser = JSON.parse(localStorage.getItem("userInfo"));
      if (this.params.data.Email !== currentUser.Email) {
        this.$goc.request.delete(
          this.$goc.ENUMS.API.DeleteUsers,
          res => {
            if (res === "") {
              this.showDeleteSuccess();
            }
          },
          { Emails: [this.params.data.Email] }
        );
      } else {
        this.$goc.notify.warning({
          title: this.$t('_user_management.Restricted_Operation'),
          message: this.$t('_user_management.You_can_not_delete_yourself')
        });
      }
    },
    showDeleteSuccess() {
      let lastSize = this.$goc
        .getModule("UserList")
        .$refs.agGridTable.getRowData();
      this.$goc.getModule("UserList").reloadDataTable(lastSize);
      this.$goc.notify.success({
        title: this.$t('_common.Success'),
        message: this.$t('_user_management.UserRemovedSuccessfuly'),
      });
    },
    resendVerificationCode() {
      let query = `${this.params.data.Email}`;
      this.$goc.request.put(
        this.$enums.API.SendVerificationMail + query,
        {},
        () => {
          this.$goc.notify.success({
            title: this.$t('_common.Message'),
            message: this.$t('_user_management.VerificationMailSended'),
          });
        }
      );
    },
    resetPassword() {
      let query = `${this.params.data.Email}`;
      this.$goc.request.put(
        this.$enums.API.AuthenticationResetPassword + query,
        {},
        () => {
          this.$goc.notify.success({
            title: this.$t('_common.Message'),
            message: this.$t('_user_management.UserPasswordResetLink'),
          });
        }
      );
    }
  }
};
</script>
