<!-- =========================================================================================
  File Name: UserList.vue
  Description: User List page
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="page-user-list">
    <!--    <vx-card-->
    <!--      ref="filterCard"-->
    <!--      title="Filters"-->
    <!--      class="user-list-filters mb-8 v-step-filter"-->
    <!--      collapseAction-->
    <!--      refreshContentAction-->
    <!--      @refresh="resetColFilters"-->
    <!--    >-->
    <!--      <div class="vx-row">-->
    <!--        <div class="vx-col md:w-1/4 sm:w-1/2 w-full">-->
    <!--          <label class="text-sm opacity-75">Role</label>-->
    <!--          <v-select-->
    <!--            :options="roleOptions"-->
    <!--            :clearable="false"-->
    <!--            :dir="$vs.rtl ? 'rtl' : 'ltr'"-->
    <!--            v-model="roleFilter"-->
    <!--            class="mb-4 md:mb-0"-->
    <!--          />-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </vx-card>-->

    <div class="vx-card p-6">
      <div class="flex flex-wrap items-center">
        <!-- ITEMS PER PAGE -->
        <div class="flex-grow">
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div
              class="
                p-4
                border border-solid
                d-theme-border-grey-light
                rounded-full
                d-theme-dark-bg
                cursor-pointer
                flex
                items-center
                justify-between
                font-medium
              "
            >
              <span class="mr-2"
                >{{ currentPage * pageSize - (pageSize - 1) }} -
                {{
                  totalData - currentPage * pageSize > 0
                    ? currentPage * pageSize
                    : totalData
                }}
                of {{ totalData }}</span
              >
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
            <vs-dropdown-menu>
              <vs-dropdown-item @click="paginationSetPageSize(10)">
                <span>10</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="paginationSetPageSize(20)">
                <span>20</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="paginationSetPageSize(25)">
                <span>25</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="paginationSetPageSize(30)">
                <span>30</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>

        <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV
        <vs-input class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4" v-model="searchQuery"
        @input="updateSearchQuery" placeholder="Search..."/>-->
        <!-- <vs-button class="mb-4 md:mb-0" @click="gridApi.exportDataAsCsv()">Export as CSV</vs-button> -->

        <v-dialog
          v-model="dialog"
          scrollable
          persistent
          max-width="1000px"
          @keydown.esc="closeUserAddDialog"
        >
          <template v-slot:activator="{ on }">
            <v-btn color="success" class="v-step-add-user mr-2" v-on="on"
              >{{ getLangText("_user_management.Add_User") }}
            </v-btn>
          </template>
          <v-card>
            <v-card-title>{{ $t("_user_management.Add_User") }}</v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <add-user></add-user>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error" @click="closeUserAddDialog"
                >{{ this.$t("_common.Close") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="csvDialog"
          scrollable
          persistent
          max-width="1000px"
          @keydown.esc="closeUserAddDialog"
        >
          <template v-slot:activator="{ on }">
            <v-btn color="success" class="v-step-add-user mr-2" v-on="on"
              >{{ getLangText("_user_management.Add_Users") }}
            </v-btn>
          </template>
          <v-card>
            <v-card-title>{{ $t("_user_management.Add_Users") }}</v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <add-users></add-users>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error" @click="closeCsvUserAddDialog"
                >{{ this.$t("_common.Close") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-btn
          color="error"
          @click="deleteSelectedUsers"
          class="v-step-add-user mr-2"
          >{{ this.$t("_user_management.Delete") }}
        </v-btn>
      </div>

      <div>
        <v-container class="m-0 p-0 col-12 mt-4">
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="searchText"
                placeholder="type search keyword..."
                id="searchText"
              ></v-text-field>
            </v-col>
            <v-col cols="4" sm="2" md="2">
              <v-select
                v-model="selectedLevel"
                name="Level"
                :options="levelList"
                placeholder="Kademe"
              />
            </v-col>
            <v-col cols="4" sm="2" md="2">
              <v-select
                v-model="selectedUnit"
                name="Unit"
                :options="unitList"
                placeholder="Birim"
              />
            </v-col>
            <v-col cols="4" sm="2" md="2">
              <v-select
                v-model="selectedTitle"
                name="Title"
                :options="titleList"
                placeholder="Ünvan"
              />
            </v-col>
            <v-col cols="4" sm="2" md="2">
              <v-select
                v-model="selectedRole"
                :options="roleOptions"
                placeholder="Rol"
              ></v-select>
            </v-col>
            <v-col cols="4" sm="2" md="2">
              <v-select
                v-model="selectedStatus"
                :options="statusOptions"
                placeholder="Durum"
              ></v-select>
            </v-col>
            <v-col cols="4" sm="2" md="2">
              <v-select
                v-model="selectedProvince"
                name="Province"
                :options="provinceList"
                placeholder="İl"
              />
            </v-col>
            <v-col cols="4" sm="2" md="2">
              <v-select
                v-model="selectedDistrict"
                name="District"
                :options="districtList"
                placeholder="İlçe"
              />
            </v-col>
            <v-col cols="4" sm="2" md="2">
              <v-btn
                color="white"
                @click="searchUsers"
                class="v-step-add-user mr-2"
              >
                <v-icon>search</v-icon>
                {{ this.$t("_user_management.Search") }}
              </v-btn>
            </v-col>
            <v-col cols="4" sm="2" md="2">
              <v-btn
                color="gray"
                @click="clearFilter"
                class="v-step-add-user mr-2"
              >
                <v-icon>clear</v-icon>
                {{ this.$t("_user_management.Clear") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <!-- AgGrid Table -->
      <ag-grid-vue
        ref="agGridTable"
        :components="components"
        :gridOptions="gridOptions"
        class="ag-theme-material w-100 my-4 ag-grid-table v-step-table"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :rowData="usersData"
        @grid-ready="onGridReady"
        rowSelection="multiple"
        colResizeDefault="shift"
        :animateRows="true"
        :floatingFilter="false"
        :pagination="true"
        :paginationPageSize="pageSize"
        :suppressPaginationPanel="true"
        :enableRtl="$vs.rtl"
      ></ag-grid-vue>

      <vs-pagination :total="totalPages" :max="7" v-model="currentPage" />
    </div>
    <v-tour name="userManagement" :steps="steps"></v-tour>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";
import vSelect from "vue-select";

// Store Module
import moduleUserManagement from "@/store/user-management/moduleUserManagement.js";

// Cell Renderer
import CellRendererLink from "./cell-renderer/CellRendererLink.vue";
import CellRendererStatus from "./cell-renderer/CellRendererStatus.vue";
import CellRendererVerified from "./cell-renderer/CellRendererVerified.vue";
import CellRendererActions from "./cell-renderer/CellRendererActions.vue";
import AddUser from "../../../AddUser.vue";
import AddUsers from "../../../AddUsers.vue";

export default {
  name: "user-management",
  components: {
    AgGridVue,
    vSelect,
    AddUser,
    AddUsers,
    // Cell Renderer
    CellRendererLink,
    CellRendererStatus,
    CellRendererVerified,
    CellRendererActions,
  },
  data() {
    return {
      selectedStatus: null,
      selectedRole: null,
      searchText: "",
      provinceList: [],
      selectedProvince: "",
      districtList: [],
      selectedDistrict: "",
      levelList: [],
      selectedLevel: "",
      unitList: [],
      selectedUnit: "",
      titleList: [],
      selectedTitle: "",
      dialogm1: "",
      dialog: false,
      csvDialog: false,
      // Filter Options
      roleFilter: { label: "All", value: "all" },
      filterModel: null,
      roleOptions: [
        { label: "Admin", value: 1 },
        { label: "Kullanıcı", value: 2 },
        { label: "Öğretmen", value: 3 },
      ],
      statusOptions: [
        { label: "Pasif", value: 1 },
        { label: "Aktif", value: 2 },
        { label: "Silinmiş", value: 3 },
      ],
      steps: [
        {
          target: ".v-step-filter",
          header: {
            title: "Filter Options",
          },
          content: `You can filter user table.`,
        },
        {
          target: ".v-step-table",
          header: {
            title: "User List",
          },
          content: `List of lms users.`,
        },
        {
          target: ".v-step-add-user",
          header: {
            title: "Add New User",
          },
          content: `You can add user by clicking this button.`,
        },
        {
          target: ".v-step-add-users",
          header: {
            title: "Add New Users",
          },
          content: `You can add users via .csv file by clicking this button.`,
        },
      ],

      searchQuery: "",

      // AgGrid
      gridApi: null,
      gridOptions: {},
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMenu: true,
      },

      totalPages: 0,
      totalData: 0,
      currentPage: 1,
      pageSize: 10,
      columnDefs: [
        {
          width: 70,
          checkboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          headerCheckboxSelection: true,
        },
        {
          headerName: this.$t("_user_management.Username"),
          field: "UserName",
          filter: true,
          width: 210,
          cellRendererFramework: "CellRendererLink",
          filterParams: {
            apply: true,
            newRowsAction: "keep",
          },
        },
        {
          headerName: this.$t("_user_management.Email"),
          field: "Email",
          filter: true,
          width: 225,
          filterParams: {
            apply: true,
            newRowsAction: "keep",
          },
        },
        {
          headerName: this.$t("_user_management.Name"),
          field: "FirstName",
          filter: true,
          width: 200,
          filterParams: {
            apply: true,
            newRowsAction: "keep",
          },
        },
        {
          headerName: this.$t("_user_management.Last_Name"),
          field: "LastName",
          filter: true,
          width: 200,
          filterParams: {
            apply: true,
            newRowsAction: "keep",
          },
        },
        {
          headerName: this.$t("_user_management.Identifier"),
          field: "Identifier",
          filter: true,
          width: 200,
          filterParams: {
            apply: true,
            newRowsAction: "keep",
          },
        },
        {
          headerName: this.$t("_user_management.Mobile"),
          field: "MobilePhoneNumber",
          filter: true,
          width: 200,
          filterParams: {
            apply: true,
            newRowsAction: "keep",
          },
        },
        {
          headerName: this.$t("_user_management.Province"),
          field: "Province.ProvinceName",
          filter: true,
          width: 200,
          filterParams: {
            apply: true,
            newRowsAction: "keep",
          },
        },
        {
          headerName: this.$t("_user_management.District"),
          field: "District.DistrictName",
          filter: true,
          width: 200,
          filterParams: {
            apply: true,
            newRowsAction: "keep",
          },
        },
        {
          headerName: this.$t("_user_management.Level"),
          field: "Level",
          filter: true,
          width: 200,
          filterParams: {
            apply: true,
            newRowsAction: "keep",
          },
        },
        {
          headerName: this.$t("_user_management.Unit"),
          field: "Unit",
          filter: true,
          width: 200,
          filterParams: {
            apply: true,
            newRowsAction: "keep",
          },
        },
        {
          headerName: this.$t("_user_management.Title"),
          field: "Title",
          filter: true,
          width: 200,
          filterParams: {
            apply: true,
            newRowsAction: "keep",
          },
        },
        {
          headerName: this.$t("_user_management.Role"),
          field: "UserRoles",
          filter: true,
          width: 150,
          filterParams: {
            apply: true,
            newRowsAction: "keep",
          },
        },
        {
          headerName: this.$t("_user_management.Status"),
          field: "Status",
          valueFormatter: (params) => {
            if (params.value == 1) {
              return this.$t(
                "_user_management._popup_Add_User._popup_Status.Passive"
              );
            } else if (params.value == 3) {
              return this.$t(
                "_user_management._popup_Add_User._popup_Status.Deleted"
              );
            } else
              return this.$t(
                "_user_management._popup_Add_User._popup_Status.Active"
              );
          },
          filter: true,
          width: 150,
          filterParams: {
            apply: true,
            newRowsAction: "keep",
          },
        },
        {
          headerName: this.$t("_user_management.Actions"),
          field: "transactions",
          width: 250,
          cellRendererFramework: "CellRendererActions",
          filterParams: {
            apply: true,
            newRowsAction: "keep",
          },
        },
      ],

      // Cell Renderer Components
      components: {
        CellRendererLink,
        CellRendererStatus,
        CellRendererVerified,
        CellRendererActions,
      },
      usersData: [],
    };
  },
  watch: {
    selectedProvince() {
      if (this.selectedProvince) {
        let query = `${this.selectedProvince.value}`;
        this.$goc.request.get(this.$enums.API.GetDisricts + query, (result) => {
          if (result.IsSuccess === true) {
            this.districtList = result.Result.Districts.map((d) => ({
              label: d.DistrictName,
              value: d.Id,
            }));
          }
        });
      } else {
        this.districtList = [];
        this.selectedDistrict = "";
      }
    },
    roleFilter(obj) {
      this.setColumnFilter("UserRoles", obj.value);
    },
    currentPage() {
      this.searchUsers();
    },
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) {
        let pageSizeData = this.gridApi.paginationGetPageSize();
        return pageSizeData;
      } else return 10;
    },
    // totalPages() {
    //   if (this.gridApi) return this.gridApi.paginationGetTotalPages();
    //   if (this.gridApi) return this.gridApi.paginationGetTotalPages();
    //   else return 0;
    // },
    // currentPage: {
    //   get() {
    //     if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
    //     else return 1;
    //   },
    //   set(val) {
    //     this.gridApi.paginationGoToPage(val - 1);
    //   }
    // }
  },
  methods: {
    getProvinceList() {
      this.$goc.request.get(this.$enums.API.GetProvinces, (result) => {
        if (result.IsSuccess === true) {
          this.provinceList = result.Result.Provinces.map((d) => ({
            label: d.ProvinceName,
            value: d.Id,
          }));
        }
      });
    },
    getLevelList() {
      this.$goc.request.get(this.$enums.API.GetUserLevels, (result) => {
        if (result.IsSuccess === true) {
          this.levelList = result.Result.Levels.map((d) => ({
            label: d,
            value: d,
          }));
        }
      });
    },
    getUnitList() {
      this.$goc.request.get(this.$enums.API.GetUserUnits, (result) => {
        if (result.IsSuccess === true) {
          this.unitList = result.Result.Units.map((d) => ({
            label: d,
            value: d,
          }));
        }
      });
    },
    getTitleList() {
      this.$goc.request.get(this.$enums.API.GetUserTitles, (result) => {
        if (result.IsSuccess === true) {
          this.titleList = result.Result.Titles.map((d) => ({
            label: d,
            value: d,
          }));
        }
      });
    },
    getLangText(_text) {
      return this.$goc.langHelper.getLangText(_text);
    },
    onDataGridFilterChange() {
      this.filterModel = this.gridApi.getFilterModel();
      this.searchUsers();
    },
    paginationSetPageSize(pageSize) {
      this.pageSize = pageSize;
      if (pageSize) {
        this.currentPage = 1;
      }
      this.searchUsers();
    },
    closeUserAddDialog() {
      this.reloadDataTable();
      this.dialog = false;
    },
    closeCsvUserAddDialog() {
      this.csvDialog = false;
    },
    reloadDataTable(lastSize) {
      if (lastSize && lastSize.length == 1) {
        if (this.currentPage > 1) {
          this.currentPage = this.currentPage - 1;
        }
      }
      this.searchUsers();
    },
    showDeleteSuccess() {
      let lastSize = this.$refs.agGridTable.getRowData();
      this.reloadDataTable(lastSize);
      this.$goc.notify.success({
        title: this.$t("_user_management.DeleteUser"),
        message: this.$t("_user_management.UserRemovedSuccessfuly"),
      });
      /*this.$vs.notify({
          color: 'success',
          title: 'User Deleted',
          text: 'The selected user was successfully deleted'
        })*/
    },
    deleteSelectedUsers() {
      // multidelete has to be added backend
      let selectedNodes = this.gridApi.getSelectedNodes();
      if (selectedNodes.length > 0) {
        this.$goc.notify.confirm(
          {
            title: this.$t("_common.Delete"),
            message:
              this.$t("_common.AreYouSure") +
              " " +
              selectedNodes.length +
              this.$t("_user_management.Selected_record_will_be_removed"),
          },
          (res) => {
            if (res) {
              let currentUser = JSON.parse(localStorage.getItem("userInfo"));
              let userMailList = selectedNodes.map((e) => e.data.Email);

              if (userMailList.indexOf(currentUser.Email) == -1) {
                this.$goc.request.delete(
                  this.$goc.ENUMS.API.DeleteUsers,
                  (res) => {
                    if (res === "") {
                      this.showDeleteSuccess();
                    }
                  },
                  { Emails: userMailList }
                );
              } else {
                this.$goc.notify.warning({
                  title: this.$t("_user_management.Restricted_Operation"),
                  message: this.$t(
                    "_user_management.You_can_not_delete_yourself"
                  ),
                });
              }
            }
          }
        );
      } else {
        this.$goc.notify.warning({
          title: this.$t("_user_management.No_Selections"),
          message: this.$t(
            "_user_management.Please_select_at_least_one_user_to_remove"
          ),
        });
      }
    },
    setColumnFilter(column, val) {
      const filter = this.gridApi.getFilterInstance(column);
      let modelObj = null;

      if (val !== "all") {
        modelObj = { column: "Roles", type: "equals", filter: val };
      }

      filter.setModel(modelObj);
      // this.gridApi.onFilterChanged();
    },
    resetColFilters() {
      // Reset Grid Filter
      this.gridApi.setFilterModel(null);
      // this.gridApi.onFilterChanged();

      // Reset Filter Options
      this.roleFilter = {
        label: "All",
        value: "all",
      };

      this.$refs.filterCard.removeRefreshAnimation();
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.searchUsers();
    },
    searchUsers() {
      const payload = {};
      if (this.searchText) {
        payload.SearchText = this.searchText;
      }
      if (this.selectedProvince) {
        payload.ProvinceId = this.selectedProvince.value;
      }
      if (this.selectedDistrict) {
        payload.DistrictId = this.selectedDistrict.value;
      }
      if (this.selectedLevel) {
        payload.Level = this.selectedLevel.value;
      }
      if (this.selectedUnit) {
        payload.Unit = this.selectedUnit.value;
      }
      if (this.selectedTitle) {
        payload.Title = this.selectedTitle.value;
      }
      if (this.selectedRole) {
        payload.UserRole = this.selectedRole.value;
      }
      if (this.selectedStatus) {
        payload.Status = this.selectedStatus.value;
      }
      payload.Limit = this.pageSize;
      if (!this.currentPage) {
        payload.Offset = 0;
      } else {
        payload.Offset = this.currentPage - 1;
      }
      this.$goc.request.post(
        this.$goc.ENUMS.API.GetAllUsers,
        payload,
        (result) => {
          if (result.IsSuccess === true) {
            this.usersData = result.Result.Users;
            this.totalPages = Math.ceil(result.Result.Count / this.pageSize);
            this.totalData = result.Result.Count;
          }
        }
      );
    },
    clearFilter() {
      this.searchText = "";
      this.selectedStatus = null;
      this.selectedRole = null;
      this.selectedProvince = null;
      this.selectedDistrict = null;
      this.selectedLevel = null;
      this.selectedUnit = null;
      this.selectedTitle = null;
      this.usersData = [];
      this.searchUsers();
    },
  },
  mounted() {
    this.$goc.setModule("UserList", this);

    this.getProvinceList();
    this.getLevelList();
    this.getUnitList();
    this.getTitleList();
    /* =================================================================
        NOTE:
        Header is not aligned properly in RTL version of agGrid table.
        However, we given fix to this issue. If you want more robust solution please contact them at gitHub
      ================================================================= */
    if (this.$vs.rtl) {
      const header = this.$refs.agGridTable.$el.querySelector(
        ".ag-header-container"
      );
      header.style.left =
        "-" + String(Number(header.style.transform.slice(11, -3)) + 9) + "px";
    }
  },
  created() {
    if (!moduleUserManagement.isRegistered) {
      this.$store.registerModule("userManagement", moduleUserManagement);
      moduleUserManagement.isRegistered = true;
    }
    // this.$store.dispatch("userManagement/fetchUsers").catch(err => {
    //   this.$goc.console.error(err);
    // });
  },
};
</script>

<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
</style>
