<template>
  <v-row>
    <v-col cols="24" md="12" sm="24">
      <v-card>
        <v-card-title
          >{{
            this.$t("_user_management._popup_Add_User.content_1")
          }}...</v-card-title
        >
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  class="p-0"
                  v-model="userForm.FirstName"
                  :label="
                    this.$t('_user_management._popup_Add_User.First_Name')
                  "
                  :rules="validations.nameRules"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  class="p-0"
                  v-model="userForm.LastName"
                  :label="this.$t('_user_management._popup_Add_User.Last_Name')"
                  :rules="validations.nameRules"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  class="p-0"
                  v-model="userForm.UserName"
                  :label="this.$t('_user_management._popup_Add_User.User_Name')"
                  :rules="validations.userNameRules"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  class="p-0"
                  v-model="userForm.Email"
                  :label="this.$t('_user_management._popup_Add_User.Email')"
                  :rules="validations.emailRules"
                  v-on:keyup="checkEmail"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  class="p-0"
                  v-model="userForm.Identifier"
                  :label="this.$t('_user_management.Identifier')"
                  :rules="validations.tcNoRules"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  class="p-0"
                  v-model="userForm.MobilePhoneNumber"
                  :label="this.$t('_user_management.Mobile')"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="12" md="6">
                <v-select
                  class="p-0"
                  v-model="userForm.UserRoles"
                  :items="userRolesSelect"
                  :rules="validations.userRolesRules"
                  item-text="text"
                  item-value="value"
                  :label="this.$t('_user_management._popup_Add_User.User_Role')"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-select
                  class="p-0"
                  v-model="userForm.Status"
                  :items="userStatusSelect"
                  :rules="validations.userStatusRule"
                  item-text="text"
                  item-value="value"
                  :label="
                    this.$t('_user_management._popup_Add_User.User_Status')
                  "
                ></v-select>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-select
                  class="p-0"
                  v-model="userForm.ProvinceId"
                  :items="provinceList"
                  item-text="label"
                  item-value="value"
                  :label="this.$t('_user_management.Province')"
                  :rules="validations.provinceRule"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-select
                  class="p-0"
                  v-model="userForm.DistrictId"
                  :items="districtList"
                  item-text="label"
                  item-value="value"
                  name="District"
                  :label="this.$t('_user_management.District')"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  class="p-0"
                  v-model="userForm.Level"
                  :label="this.$t('_user_management.Level')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  class="p-0"
                  v-model="userForm.Unit"
                  :label="this.$t('_user_management.Unit')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  class="p-0"
                  v-model="userForm.Title"
                  :label="this.$t('_user_management.Title')"
                ></v-text-field>
              </v-col>
            </v-row>
            <vs-row align="end" justify="end">
              <v-col>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      @click="addUser"
                      class="mr-2"
                      v-on="on"
                      dark
                      color="success"
                    >
                      {{ $t("_common.Add") }}
                    </v-btn>
                    <v-btn
                      @click="resetAddUserForm"
                      class="mr-2"
                      v-on="on"
                      dark
                      color="warning"
                      >{{ $t("_common.Reset") }}
                    </v-btn>
                  </template>
                  <span>{{
                    $t("_user_management._popup_Add_User.Hover.Create_a_user")
                  }}</span>
                </v-tooltip>
              </v-col>
            </vs-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
require("../../assets/css/neo.css");

export default {
  data() {
    return {
      valid: true,
      show1: false,
      successFile: false,
      errorFile: false,
      userRolesSelect: [],
      userStatusSelect: [],
      provinceList: [],
      districtList: [],
      validations: {
        userRolesRules: this.$goc.validate.create().required(),
        passwordRules: this.$goc.validate.create().required().min(6),
        emailRules: this.$goc.validate.create().required().email(),
        userNameRules: this.$goc.validate.create().required().min(3).max(20),
        nameRules: this.$goc.validate.create().required().min(2).max(50),
        userStatusRule: this.$goc.validate.create().required(),
        tcNoRules: this.$goc.validate.create().min(11).max(11),
        provinceRule: this.$goc.validate.create().required(),
      },
      userForm: {
        FirstName: null,
        LastName: null,
        UserName: "",
        Email: "",
        UserRoles: "",
        Status: "",
        SendEmail: false,
        Identifier: null,
        MobilePhoneNumber: null,
        ProvinceId: null,
        SelectedDistrict: null,
        Title: "",
        Level: "",
        Unit: "",
      },
      multiUserForm: {
        files: null,
      },
      emailCheckTimer: null,
      emailRegularExp: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
    };
  },
  watch: {
    "userForm.ProvinceId"() {
      if (this.userForm.ProvinceId) {
        let query = `${this.userForm.ProvinceId}`;
        this.$goc.request.get(this.$enums.API.GetDisricts + query, (result) => {
          if (result.IsSuccess === true) {
            this.districtList = result.Result.Districts.map((d) => ({
              label: d.DistrictName,
              value: d.Id,
            }));
          }
        });
      } else {
        this.districtList = [];
        this.userForm.DistrictId = "";
      }
    },
  },
  mounted() {
    this.getProvinceList();
    this.$goc.request.common.getRoles((roleList) => {
      this.userRolesSelect = roleList;
    });
    this.$goc.setModule("AddUser", this);

    this.$goc.request.common.getUserStatus((statusList) => {
      this.userStatusSelect = statusList;
    });
  },
  methods: {
    resetAddUserForm() {
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },
    checkEmail() {
      if (this.emailRegularExp.test(this.userForm.Email)) {
        clearTimeout(this.emailCheckTimer);
        this.emailCheckTimer = setTimeout(() => {
          this.$goc.request.post(this.$enums.API.UserEmailCheck, {
            Email: this.userForm.Email,
          });
        }, 1000);
      }
    },
    addUser() {
      if (this.validate()) {
        this.userForm.SendEmail = this.passwordCheck;
        this.$goc.request.post(
          this.$enums.API.AddUser,
          this.userForm,
          (response) => {
            this.$refs.form.reset();
            this.$refs.form.resetValidation();
            if (response.IsSuccess) {
              this.$goc.notify.success({
                title: this.$t("_common.Success"),
                message: this.$t(
                  "_user_management._popup_Add_User.UserSuccessfullyCreated"
                ),
              });
            } else {
              this.$goc.notify.error({
                title: this.$t("_common.Failed"),
                message: this.$t("_user_management._popup_Add_User.UserExists"),
              });
            }
          },
          false,
          this.$t("_user_management._popup_Add_User.UserExists")
        );
      }
    },
    removePasswordValidation() {
      if (this.passwordCheck) {
        this.userForm.password = "";
        this.validations.passwordRules = this.$goc.validate.clear();
      } else {
        this.validations.passwordRules = this.$goc.validate
          .create()
          .required()
          .min(6);
      }
    },
    validate() {
      return !!this.$refs.form.validate();
    },
    getProvinceList() {
      this.$goc.request.get(this.$enums.API.GetProvinces, (result) => {
        if (result.IsSuccess === true) {
          this.provinceList = result.Result.Provinces.map((d) => ({
            label: d.ProvinceName,
            value: d.Id,
          }));
        }
      });
    },
  },
};
</script>
